.dash-menu {
  height: var(--dash-menu-height);
  min-height: var(--dash-menu-height);
  max-height: var(--dash-menu-height);
  background-color: var(--dash-menu-background-color);
}

.dash-shell {
  display: flex;
  flex-direction: column;
}

.dash-body {
  display: flex;
  flex-direction: row;
}
