.sidebar_search {
  height: 40px;
  padding: 0 3px 0 2px;
}

.sidebar_container {
  width: 100%;
  border-top-width: var(--dash-menu-border-width);
  border-top-style: var(--dash-menu-border-style);
  border-top-color: var(--dash-menu-border-color);
}

.sidebar_treeview {
  border-top-width: var(--dash-menu-border-width);
  border-top-style: var(--dash-menu-border-style);
  border-top-color: var(--dash-menu-border-color);
}

.sidebar_treeview :global .e-list-parent .e-ul {
  padding: 0 !important;
}

.sidebar_treeview :global .e-list-item {
  padding: 0 !important;
}

.sidebar_treeview :global .e-text-content {
  padding: 0 !important;
}
