@import "../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-gantt/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-grids/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-layouts/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-richtexteditor/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-treegrid/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-react-navigations/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-react-grids/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/fabric.css";
@import "../node_modules/@syncfusion/ej2-react-inplace-editor/styles/fabric.css";

.e-search-icon:before {
  content: "\ebe6";
}

.e-close-icon:before {
  content: "\ebe8";
}

.e-menu-icon:before {
  content: "\e714";
  text-align: center;
  vertical-align: middle;
}

body {
  font-family: var(--dash-font-family);
  font-size: 13px;
}
