@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --dash-menu-border-color: #dadada;
  --dash-menu-background-color: rgba(244, 244, 244, 255);
  --dash-menu-border-width: 1px;
  --dash-menu-border-style: solid;

  --dash-menu-height: 42px;
  --dash-icon-width: var(--dash-menu-height);
  --dash-icon-height: var(--dash-menu-height);

  --dash-font-family: "Segoe UI", "GeezaPro", "DejaVu Serif", sans-serif, "-apple-system", "BlinkMacSystemFont";
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* this fixes the gantt issue */
div.e-gridcontent.e-responsive-header > div {
  height: calc(100vh - 149px) !important;
}
