.header {
  background-color: var(--dash-menu-background-color) !important;
  height: var(--dash-menu-height);

  border-bottom-width: var(--dash-menu-border-width);
  border-bottom-style: var(--dash-menu-border-style);
  border-bottom-color: var(--dash-menu-border-color);
}

.back_icon {
  margin-top: 8px;
  margin-left: 8px;
  cursor: pointer;
}

.product_name {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
}

.content_container {
  margin-left: 20px;
  margin-right: 20px;
}

.content {
  padding-top: calc(var(--dash-menu-height) + 10px);
}
