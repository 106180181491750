.menu_hamburger_container {
  height: var(--dash-icon-height);
  width: var(--dash-icon-width);
  padding-top: 9px;
  padding-left: 9px;
  padding-right: 9px;
  background-color: rgb(156, 156, 156);
}

.menu_hamburger_container:hover {
  background-color: #eaeaea;
  cursor: pointer;
}

.menu :global .e-menu {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.menu_title {
  margin-top: 4px;
}

.dash_menu_center {
  color: #333;
}

.dash_menu_right {
  padding-right: 10px;
  display: flex;
  flex-direction: row;

  color: #333;
  font-size: 13px;
}

.version_info {
  align-self: center;
}

.menu_project_name_input {
  margin-top: 10px;
  padding: 2px 4px 2px 4px;
  align-self: center;
  justify-self: center;
  font-size: 12px;
}

.dash-e-menu-item {
  background-color: red;
}

.dash-e-menu {
  background-color: red;
}

.menu_item {
  height: 42px;
  margin-left: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 11px;
  cursor: pointer;
  text-align: center;
  align-items: center;
}

.menu_item:hover {
  background-color: #eaeaea;
  cursor: pointer;
}
